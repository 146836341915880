import React from "react"
import theme from "theme"
import { Theme, Text, Hr, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"index"} />
      <Helmet>
        <title>Home | Wash Oto Yıkama</title>
        <meta
          name={"description"}
          content={"Aracınızı Şık Bir Şekilde Yenileyin!"}
        />
        <meta property={"og:title"} content={"Home | Wash Oto Yıkama"} />
        <meta
          property={"og:description"}
          content={"Aracınızı Şık Bir Şekilde Yenileyin!"}
        />
        <meta
          property={"og:image"}
          content={"https://wixerastand.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://wixerastand.com/img/6572572.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://wixerastand.com/img/6572572.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://wixerastand.com/img/6572572.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://wixerastand.com/img/6572572.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://wixerastand.com/img/6572572.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://wixerastand.com/img/6572572.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        background="url(https://wixerastand.com/img/1.jpg) center/cover"
        padding="140px 0"
        sm-padding="40px 0"
        quarkly-title="About-16"
      >
        <Override
          slot="SectionContent"
          align-items="center"
          justify-content="center"
          sm-min-width="280px"
        />
        <Box
          padding="50px 80px 50px 50px"
          color="--dark"
          display="flex"
          flex-direction="column"
          align-items="center"
          max-width="700px"
          background="#ffffff"
          sm-padding="50px 24px 50px 24px"
        >
          <Text margin="0 0 16px 0" as="h1" font="--headline2">
            Wash Oto Yıkama
          </Text>
          <Hr
            min-height="10px"
            margin="0px 0px 0px 0px"
            border-color="--color-dark"
            border-width="3px 0 0 0"
            max-width="120px"
            width="100%"
          />
          <Text font="--base" text-align="center">
            Temiz bir arabanın mutlu bir sürücüye eşit olduğuna inandığımız Wash
            Oto Yıkama'ya hoş geldiniz! Self-servis tesislerimiz size rahat,
            verimli ve keyifli bir deneyim sunmak için tasarlanmıştır. İster
            hızlı bir durulama ister detaylı bir yıkama için burada olun, size
            hizmet etmekten ve aracınızın parlamasına yardımcı olmaktan
            memnuniyet duyarız.
          </Text>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-15"
      >
        <Override slot="SectionContent" flex-wrap="wrap" flex-direction="row" />
        <Box
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 0px 16px"
          lg-order="1"
          width="50%"
          align-items="flex-start"
          lg-align-items="center"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
          display="flex"
          flex-direction="column"
          lg-width="100%"
          justify-content="space-between"
        >
          <Box display="flex" flex-direction="column">
            <Text
              margin="0px 0px 36px 0px"
              color="--darkL2"
              font="--headline2"
              lg-text-align="center"
              sm-font="--headline3"
              sm-margin="0px 0px 20px 0px"
              lg-margin="0px 0px 18px 0px"
              md-text-align="left"
            >
              Hakkımızda
            </Text>
            <Text
              margin="0px 0px 0px 0px"
              color="--darkL2"
              font="--base"
              lg-text-align="center"
              md-text-align="left"
            >
              Wash'te bir oto yıkamadan çok daha fazlasını, aracınız için bir
              sığınak sunuyoruz. Çevre dostu sabunlarımız ve yüksek basınçlı
              jetlerimiz kapsamlı bir temizlik sağlarken, geniş seçenek
              yelpazemiz yıkamanızı kişiselleştirmenizi sağlar. Cila
              uygulamalarından alt takım patlamalarına kadar, aracınızın en iyi
              halini ortaya çıkarmak için ihtiyacınız olan her şeye sahibiz.
            </Text>
          </Box>
        </Box>
        <Box
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
          width="50%"
          lg-width="100%"
        >
          <Box width="100%">
            <Image
              src="https://wixerastand.com/img/2.jpg"
              width="100%"
            />
          </Box>
        </Box>
      </Section>
      <Section padding="90px 0 100px 0" quarkly-title="News/Streams/Blog-7">
        <Text
          margin="0px 0px 50px 0px"
          font="normal 600 42px/1.2 --fontFamily-sans"
        >
          Neden Wash'i Seçmelisiniz?
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(4, 1fr)"
          grid-gap="0 40px"
          md-grid-template-columns="1fr"
          md-grid-gap="50px 0"
        >
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            margin="0px 0px 0 0px"
            md-flex-direction="column"
            flex-direction="column"
            justify-content="space-between"
            md-justify-content="flex-start"
          >
            <Box
              min-width="100px"
              min-height="100px"
              md-margin="0px 0px 30px 0px"
              margin="0px 0px 0 0px"
              display="flex"
              flex-direction="column"
              md-width="100%"
              md-justify-content="flex-start"
            >
              <Box min-width="100px" min-height="100px">
                <Text
                  margin="0px 0px 10px 0px"
                  font="normal 600 24px/1.2 --fontFamily-sans"
                  lg-margin="0px 0px 15px 0px"
                >
                  Çevre Dostu Çözümler
                </Text>
                <Text
                  margin="0px 0px 20px 0px"
                  font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                  lg-margin="0px 0px 18px 0px"
                  color="#626970"
                  md-margin="0px 0px 25px 0px"
                >
                  Biyolojik olarak parçalanabilen sabunlarımız ve su ıslah
                  sistemlerimiz gezegene olan bağlılığımızı göstermektedir.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            margin="0px 0px 0 0px"
            md-flex-direction="column"
            flex-direction="column"
            justify-content="space-between"
            md-justify-content="flex-start"
          >
            <Box
              min-width="100px"
              min-height="100px"
              md-margin="0px 0px 30px 0px"
              margin="0px 0px 0 0px"
              display="flex"
              flex-direction="column"
              md-width="100%"
              md-justify-content="flex-start"
            >
              <Box min-width="100px" min-height="100px">
                <Text
                  margin="0px 0px 10px 0px"
                  font="normal 600 24px/1.2 --fontFamily-sans"
                  lg-margin="0px 0px 15px 0px"
                >
                  Son Teknoloji Ekipmanlar
                </Text>
                <Text
                  margin="0px 0px 20px 0px"
                  font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                  lg-margin="0px 0px 18px 0px"
                  color="#626970"
                  md-margin="0px 0px 25px 0px"
                >
                  Her seferinde lekesiz bir sonuç için en son araba yıkama
                  teknolojisinin keyfini çıkarın.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            margin="0px 0px 0 0px"
            md-flex-direction="column"
            flex-direction="column"
            justify-content="space-between"
            md-justify-content="flex-start"
          >
            <Box
              min-width="100px"
              min-height="100px"
              md-margin="0px 0px 30px 0px"
              margin="0px 0px 0 0px"
              display="flex"
              flex-direction="column"
              md-width="100%"
              md-justify-content="flex-start"
            >
              <Box min-width="100px" min-height="100px">
                <Text
                  margin="0px 0px 10px 0px"
                  font="normal 600 24px/1.2 --fontFamily-sans"
                  lg-margin="0px 0px 15px 0px"
                >
                  Self Servis Basitleştirildi
                </Text>
                <Text
                  margin="0px 0px 20px 0px"
                  font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                  lg-margin="0px 0px 18px 0px"
                  color="#626970"
                  md-margin="0px 0px 25px 0px"
                >
                  Kullanıcı dostu arayüzler ve takip etmesi kolay talimatlar
                  yıkama deneyiminizi sorunsuz hale getirir.
                </Text>
              </Box>
            </Box>
          </Box>
          <Box
            min-width="100px"
            min-height="100px"
            display="flex"
            margin="0px 0px 0 0px"
            md-flex-direction="column"
            flex-direction="column"
            justify-content="space-between"
            md-justify-content="flex-start"
          >
            <Box
              min-width="100px"
              min-height="100px"
              md-margin="0px 0px 30px 0px"
              margin="0px 0px 0 0px"
              display="flex"
              flex-direction="column"
              md-width="100%"
              md-justify-content="flex-start"
            >
              <Box min-width="100px" min-height="100px">
                <Text
                  margin="0px 0px 10px 0px"
                  font="normal 600 24px/1.2 --fontFamily-sans"
                  lg-margin="0px 0px 15px 0px"
                >
                  Uygun Fiyatlı Mükemmellik
                </Text>
                <Text
                  margin="0px 0px 20px 0px"
                  font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
                  lg-margin="0px 0px 18px 0px"
                  color="#626970"
                  md-margin="0px 0px 25px 0px"
                >
                  Üst düzey fiyat olmadan üst düzey temizlik elde edin.
                  Rekabetçi fiyatlarımız kaliteden ödün vermek zorunda
                  kalmayacağınız anlamına gelir.
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
